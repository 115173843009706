<template>
  <v-col
    cols="12"
    xs="12"
    sm="6"
    md="6"
    xl="3"
    class="align-stretch d-flex responsive-card"
  >
    <v-card width="100%" elevation="4" fill-height>
      <v-img
        position="top center"
        contain
        v-if="instance.screenshot_url"
        :src="instance.screenshot_url"
      />
      <InstanceCardImgNotAvailable
        :instance="instance"
        style="z-index: 0"
        v-else
      />

      <div class="actions d-flex flex-column">
        <v-btn
          style="min-width: 184px; max-width: 184px; background: white"
          small
          :href="instance.url"
          target="_blank"
          elevation="0"
          color="white"
          outlined
        >
          <span class="font-weight-bold base--text">{{
            $t("button.visitWebsite")
          }}</span>
        </v-btn>
        <v-btn
          v-if="$store.state.home.app.page_speed_enabled"
          style="min-width: 184px; max-width: 184px; background: white"
          class="mt-4"
          small
          elevation="0"
          color="gray"
          outlined
          :loading="thumbnailLoading"
          @click="refreshThumbnail"
        >
          <span class="font-weight-bold base--text">{{
            $t("button.refresh.thumbnail")
          }}</span>
        </v-btn>
        <div class="p-4 mt-4" v-if="!instance.screenshot_url">
          {{ $t("message.notPreview.title") }}
          <v-tooltip
            transition="custom-tooltip"
            open-delay="150"
            bottom
            z-index="99"
            offset-overflow
            nudge-bottom="4px"
          >
            <template v-slot:activator="{ on, attrs }">
              <a href="#" v-on="on" v-bind="attrs">{{
                $t("message.notPreview.suffix")
              }}</a>
            </template>

            <span v-if="instance.isNew()">
              {{ $t("message.notPreview.info.new") }}
            </span>
            <span v-else>
              {{ $t("message.notPreview.info.other") }}
            </span>
          </v-tooltip>
        </div>
      </div>
    </v-card>
  </v-col>
</template>

<script>
import Api from "@/apis/Api";
import InstanceCardImgNotAvailable from "../instances/InstanceCardImgNotAvailable.vue";

export default {
  props: {
    instance: Object,
  },
  data: function () {
    return {
      thumbnailLoading: false,
    };
  },
  methods: {
    async refreshThumbnail() {
      this.thumbnailLoading = true;
      await Api.post(`/instances/${this.instance.id}/refresh-thumbnail`)
        .then(() => {
          this.instance.refreshScreenshot();
        })
        .catch((error) => {
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
        })
        .finally(() => {
          this.thumbnailLoading = false;
        });
    },
  },
  components: { InstanceCardImgNotAvailable },
};
</script>

<style lang="scss" scoped>
.v-card {
  position: relative;
  overflow: hidden;

  .actions {
    position: absolute;
    background: transparent;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(8px);
    opacity: 0;
    transition: opacity 0.24s ease;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:hover {
    .actions {
      opacity: 1;
    }
  }
}
</style>
